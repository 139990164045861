import { ELEMENT_ID } from 'utils/constant'
import { LeftSlashSvg } from '../svg/left-slash'
import { RightSlashSvg } from '../svg/right-slash'
import { TopExtendQuestionCollapse } from './collapse'
import { getGTM } from 'utils/functions'

export function TopExtendQuestions() {
  return (
    <div
      id={ELEMENT_ID.FAQ}
      className='max-w-[1000px] mx-auto w-full flex flex-col relative d-pt-[95px] d-pb-[100px]'
    >
      <div className='flex items-center justify-center d-gap-[8px]'>
        <LeftSlashSvg className='d-w-[15px] d-h-[22px]' />
        <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
          よくある質問
        </p>
        <RightSlashSvg className='d-w-[15px] d-h-[22px]' />
      </div>

      <div className='flex items-center d-gap-[12px] d-mt-[16px]'>
        <span className='rounded-full bg-accent-green d-w-[18px] d-h-[18px]' />
        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
          ダウンロード前
        </p>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='Comadoは誰が利用できますか？'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード前_１',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-center d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px]'
            />
            <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
              サントリーウエルネスの会員の方はご利用いただけます。
              <br />
              定期お届けコースのご契約が無い場合は、「美感遊創」の記事を閲覧できません。
              <br />
              ※利用できないコンテンツは、今後変更になる可能性がございます。
            </p>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='Comadoの利用に料金はかかりますか？'
          duration={0.2}
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード前_２',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-center d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px]'
            />
            <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
              サントリーウエルネスの会員の方は、無料でお使いいただけます。
            </p>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='flex items-center d-gap-[12px] d-mt-[40px]'>
        <span className='rounded-full bg-accent-green d-w-[18px] d-h-[18px]' />
        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
          アプリダウンロード後の初期設定
        </p>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='お客様番号を確認したいです。'
          duration={0.5}
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_１',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px] d-mt-[9px]'
            />

            <div className='flex flex-col font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
              <p>
                お客様番号はサントリーウエルネスより発行させていただいております
                <br />
                『郵送物』や『納品書』よりご確認いただけます。
              </p>

              <div className='flex items-center d-gap-[16px] d-mt-[16px]'>
                <div
                  aria-hidden
                  className='d-w-[12px] d-h-[12px] d-rounded-[2px] bg-illust-tealblue'
                />
                <p>ダイレクトメール</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-01.webp'
                alt='pic-01'
                className='d-w-[409px] d-mt-[8px] object-contain'
              />

              <div className='flex items-center d-gap-[16px] d-mt-[16px]'>
                <div
                  aria-hidden
                  className='d-w-[12px] d-h-[12px] d-rounded-[2px] bg-illust-tealblue'
                />
                <p>納品書</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-02.webp'
                alt='pic-02'
                className='d-w-[409px] d-mt-[8px] object-contain'
              />

              <div className='flex items-center d-gap-[16px] d-mt-[16px]'>
                <div
                  aria-hidden
                  className='d-w-[12px] d-h-[12px] d-rounded-[2px] bg-illust-tealblue'
                />
                <p>サントリーウエルネスオンライン（マイページ）</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-03.webp'
                alt='pic-03'
                className='d-w-[409px] d-mt-[8px] object-contain'
              />
            </div>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='【iPhone】ログイン後のヘルスケア連携とは何ですか？'
          duration={0.4}
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_２',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px] d-mt-[9px]'
            />
            <div className='flex flex-col'>
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                ヘルスケア連携を行うと、Comadoで歩数を計測できるようになります。ログイン後にヘルスケアのデータのアクセスを問われたら、下記の手順で設定してください。
              </p>

              <div className='flex items-center d-gap-[8px] d-mt-[16px]'>
                <div className='d-w-[24px] d-h-[24px] rounded-full d-border-[2px] border-illust-tealblue flex items-center justify-center'>
                  <span className='d-text-[16px] d-leading-[16px] text-black font-semibold'>
                    1
                  </span>
                </div>
                <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                  「すべてオンにする」をタップする
                </p>
              </div>

              <img
                src='/images/top-extend/questions/4/pic-01.webp'
                alt='pic-01'
                className='d-w-[408px] d-mt-[8px] object-contain'
              />

              <div className='flex items-center d-gap-[8px] d-mt-[16px]'>
                <div className='d-w-[24px] d-h-[24px] rounded-full d-border-[2px] border-illust-tealblue flex items-center justify-center'>
                  <span className='d-text-[16px] d-leading-[16px] text-black font-semibold'>
                    2
                  </span>
                </div>
                <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                  右上「許可」をタップする
                </p>
              </div>

              <img
                src='/images/top-extend/questions/4/pic-02.webp'
                alt='pic-02'
                className='d-w-[408px] d-mt-[8px] object-contain'
              />

              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] d-mt-[8px] font-medium'>
                ※ご登録後、お客様番号がマイページに反映されるまで半日〜2日程度お時間をいただいております。
              </p>
            </div>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='【iPhone】ログイン後の通知許諾とは何ですか？'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_３',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px] d-mt-[9px]'
            />
            <div className='flex flex-col'>
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-medium'>
                通知を許可すると、歩数状況や登録した健康習慣のお知らせ、
                <br />
                予約したレッスンの開催連絡をアプリから受け取ることができます。
                <br />
                ログイン後に通知の許諾を問われたら、右下の「許可」をタップしてください。
              </p>
              <img
                src='/images/top-extend/questions/5/pic-01.webp'
                alt='pic-01'
                className='d-w-[408px] d-mt-[24px] object-contain'
              />
            </div>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='【Android】ログイン後の通知許諾とは何ですか？'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_４',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px] d-mt-[9px]'
            />
            <div className='flex flex-col'>
              <p className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                通知を許可すると、歩数状況や登録した健康習慣のお知らせ、
                <br />
                予約したレッスンの開催連絡をアプリから受け取ることができます。
                <br />
                ログイン後に通知の許諾を問われたら、「許可」をタップしてください。
              </p>
              <img
                src='/images/top-extend/questions/6/pic-01.webp'
                alt='pic-01'
                className='d-w-[408px] d-mt-[24px] object-contain'
              />
            </div>
          </div>
        </TopExtendQuestionCollapse>
      </div>

      <div className='flex items-center d-gap-[12px] d-mt-[40px]'>
        <span className='rounded-full bg-accent-green d-w-[18px] d-h-[18px]' />
        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
          その他
        </p>
      </div>

      <div className='d-mt-[24px]'>
        <TopExtendQuestionCollapse
          title='Comadoを退会するにはどうしたらよいですか？'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'その他_1',
          })}
        >
          <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='object-contain d-w-[42px] d-mt-[9px]'
            />

            <div className='flex flex-col font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
              <p>
                Comadoアプリ内の「よくある質問」から「Comado全般」をタップし、その中の「Comadoを退会したい」をタップしてください。
                <br />
                ページ下部にある「Comadoを退会する」をタップすると、「Comadoを退会しますか」という画面が出てくるので「退会する」をタップすると、Comadoを退会できます。
              </p>

              <img
                src='/images/top-extend/questions/7/pic-01.webp'
                alt='その他-01'
                className='d-w-[408px] d-mt-[24px] object-contain'
              />

              <p className='d-mt-[8px] font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                ※Comadoを退会すると、利用履歴や登録情報は全て削除されます。
                <br />
                <span className='d-ml-[18px]'>
                  チャレンジ達成状況(ポイント進呈履歴)については、引き続きご確認が可能です。
                </span>
                <br />
                ※貯まったサントリーウエルネスポイントは、有効期限内であれば、そのまま残り、利用することができます。
                <br />
                ※複数端末でご利用の場合は、全ての端末でログアウトすることで退会いただけます。
              </p>
            </div>
          </div>
        </TopExtendQuestionCollapse>
      </div>
    </div>
  )
}
